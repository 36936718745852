import domReady from '@wordpress/dom-ready';
import { sprintf, _nx } from '@wordpress/i18n';

domReady(() => {
  const findstr = window.findstr;
  const countP = document.querySelector('.regulations-results-count');

  function adjustPressCount(total) {
    const newText = sprintf(
      /* translators: %s is number of results */
      _nx('%s result', '%s results', total, 'listing results count', 'vtx'),
      total
    );
    countP.textContent = newText;
  }

  if (findstr && countP) {
    findstr.hooks.addAction(
      'searchResults',
      'laval-regulations',
      (results, group) => {
        if ('regulations' === group) {
          setTimeout(adjustPressCount(results.totalHits), 300);
        }
      }
    );
  }
});
